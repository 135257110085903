<template>
  <div class="checkPucode">
    <div class="pucodePage">
        <div class="pucodeBox" v-loading="authloading" element-loading-text="正在开通中，会员服务即将生效">
            <!-- 分享文件-分享成功---E -->
            <img class="avatar" :src="auth_logo">
            <span class="desc">{{ auth_name }}的私密分享</span>
            <el-input placeholder="请输入取件码" v-model="pucode"></el-input>
            <span class="btn" @click="handlePucode()">取件</span>
            <!-- <a class="link" target="_blank" href="https://wwwtest.feimaoyun.com/vd/MDAwMDAwMDAwMH7Qfq6DjYacs9yH3Yiqu5uFc3Ce">不会取件？查看视频教程</a> -->
            <span class="linkDesc">该链接由用户：{{ auth_name }}自行加密创建，并不代表本站立场</span>
            <img class="banner" :src="adData.cover" @click="goAdBanner()">
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        auth_name:String,
        auth_logo:String,
        pucode:String,
        adData:Object,
        authloading: {
          type: Boolean,
          default: false,
        },
    },
    data(){
        return{
        }
    },
    methods:{
        goAdBanner(){
            window.open(this.adData.link)
        },
        handlePucode(){
            this.$emit('handlePucode',this.pucode)
        }
    }
}
</script>

<style lang="scss">
.checkPucode{
    @include useTheme{
        background: getVar('text-color2-f9f9f9');
    }
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
}
.pucodePage {
    height: calc(100vh - 56px);
    display: flex;
    align-items: center;
    .pucodeBox {
        margin: 0 auto;
        width: 576px;
        height: 666px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        @include useTheme{
            background: getVar("text-color3-ffffff");
            border: getVar("border-color2-ffffff");
        }
        .avatar {
            width: 64px;
            height: 64px;
            margin-top:48px;
            border-radius: 50%;
            object-fit: cover;
        }
        .desc{
            font-size: 18px;
            line-height: 26px;
            margin-top: 16px;
            margin-bottom: 36px;
            font-weight: 500;
            @include useTheme{
                color: getVar("text-color2-2c2c2c");
            }
        }
        .el-input{
            width: 324px;
            height: 52px;
            .el-input__inner{
                width: 324px;
                height: 52px;
                border-radius: 10px;
                background: #F6F6F6;
            }
            input::-webkit-input-placeholder {
                text-align: center;
            }
            input:-ms-input-placeholder { // IE 10 +
                text-align: center;
            }
            input:-moz-placeholder { // Firefox 4 -18
                text-align: center;
            }
            input::-moz-placeholder { // Firefox 19 +
                text-align: center;
            }
        }
        .btn{
            width: 324px;
            height: 52px;
            background: #F67942;
            border-radius: 10px;
            line-height: 52px;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 40px;
            display: inline-block;
            text-align: center;
            margin-top: 16px;
            cursor: pointer;
        }
        .link{
            font-size: 12px;
            font-weight: 500;
            color: #F67942;
            line-height: 20px;
            text-decoration: none;
        }
        .linkDesc{
            font-size: 12px;
            line-height: 20px;
            margin-top: 8px;
            @include useTheme{
                color: getVar("text-color1-8a8b92");
            }
        }
        .banner{
            width: 476px;
            height: 185px;
            cursor: pointer;
            margin-top: 43px;
            object-fit: cover;
            object-position: center;
        }
    }
}
</style>
<style scoped>
::v-deep .el-loading-mask{
  border-radius: 16px;
}
</style>
